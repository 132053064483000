<template>
  <LineChart
    :chart-options="chartOptions"
    :chart-data="chartData"
    :style="styles"
  />
</template>

<script>
import { Line as LineChart } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);
export default {
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    chartOptions: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0.4,
          plugins: {
            legend: {
              display: true,
            },
          },
        };
      },
    },
    chartData: {
      type: Object,
      default() {
        return {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "Group 1",
              backgroundColor: "#E15241",
              borderColor: "#E15241",
              data: [40, 39, 10, 40, 39, 80, 40],
            },
            {
              label: "Group 2",
              backgroundColor: "#F19D38",
              borderColor: "#F19D38",
              data: [20, 29, 8, 30, 35, 59, 20],
            },
            {
              label: "Group 3",
              backgroundColor: "#54B9D1",
              borderColor: "#54B9D1",
              data: [7, 19, 14, 35, 35, 30, 25],
            },
          ],
        };
      },
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    LineChart,
  },
  data() {
    return {
      // chartData: {
      //   labels: [
      //     "January",
      //     "February",
      //     "March",
      //     "April",
      //     "May",
      //     "June",
      //     "July",
      //   ],
      //   datasets: [
      //     {
      //       label: "Group 1",
      //       backgroundColor: "#E15241",
      //       borderColor: "#E15241",
      //       data: [40, 39, 10, 40, 39, 80, 40],
      //     },
      //     {
      //       label: "Group 2",
      //       backgroundColor: "#F19D38",
      //       borderColor: "#F19D38",
      //       data: [20, 29, 8, 30, 35, 59, 20],
      //     },
      //     {
      //       label: "Group 3",
      //       backgroundColor: "#54B9D1",
      //       borderColor: "#54B9D1",
      //       data: [7, 19, 14, 35, 35, 30, 25],
      //     },
      //   ],
      // },
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   lineTension: 0.4,
      //   plugins: {
      //     legend: {
      //       display: true,
      //     },
      //   },
      // },
    };
  },
};
</script>

<style lang="scss" scoped></style>
