<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-3 d-flex flex-column">
        <v-card flat outlined class="flex d-flex align-center justify-center"
          ><v-card-text>
            <v-img
              src="@/assets/temp/usa-financial-logo-stacked.png"
            /> </v-card-text></v-card
      ></v-col>
      <v-col class="col-12 col-sm-4 d-flex flex-column">
        <v-card outlined class="flex h-100" elevation="12"
          ><v-card-text class="text-center d-flex flex-column fill-height">
            <h5 class="mb-4 text-h4">{{ detailItem.title }}</h5>
            <v-divider class="mb-4"></v-divider>
            <div class="d-flex flex-column flex justify-center align-center">
              <div
                class="
                  mb-2
                  text-h6 text-md-h5 text-lg-h3
                  font-weight-bold
                  text-uppercase
                "
              >
                Identified
              </div>
              <div
                class="
                  mb-2
                  success--text
                  text-h5 text-md-h4 text-lg-h1
                  font-weight-bold
                "
              >
                {{
                  currencyFormatter(
                    (detailItem.aum / 100000).toFixed() * 100000
                  )
                }}
              </div>
              <div>Assets Under Management (AUM)</div>
            </div>
          </v-card-text></v-card
        ></v-col
      >
      <v-col class="col-12 col-sm-5 d-flex flex-column">
        <v-card flat outlined class="flex">
          <v-list>
            <v-list-item class="flex-column">
              <ActivityLineChart
                :chartOptions="activityChartOptions"
                :chartData="activityChartData"
                :styles="chartStyles"
              />

              <!-- <v-sparkline
                :labels="labels"
                :value="value"
                color="primary"
                line-width="2"
                padding="8"
                smooth
              ></v-sparkline> -->
              <div style="position: absolute; bottom: 1rem">
                Sales Opportunities by Day
              </div>
            </v-list-item>

            <!-- <v-list-item class="mt-2">
                  <div class="d-flex align-center">
                    <v-progress-circular
                      :rotate="-90"
                      :size="50"
                      :width="8"
                      :value="averageRiskScoreAllProspects"
                      color="primary"
                    >
                      {{ averageRiskScoreAllProspects }}
                    </v-progress-circular>

                    <span class="ml-4">Average Risk Score</span>
                  </div>
                </v-list-item> -->
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-card flat outlined class="mt-8">
      <v-card-text>
        <v-row class="align-center">
          <v-col>
            <div class="d-flex align-center justify-center">
              <v-chip dark label color="primary" outlined class="mr-4">
                <span class="text-h3">{{
                  detailItem.advisors.items.length
                }}</span></v-chip
              >
              <span class="ml-4 font-weight-bold">Active Advisors</span>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <div class="d-flex align-center justify-center">
              <v-chip dark label outlined color="orange darken-3">
                <span class="text-h3">{{
                  countProspectCompletedSurveys
                }}</span></v-chip
              ><span class="ml-4 font-weight-bold">Completed Surveys</span>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <div class="d-flex align-center justify-center">
              <v-chip dark label outlined color="indigo"
                ><span class="text-h3">{{
                  detailItem.recProductsCount
                }}</span></v-chip
              ><span class="ml-4 font-weight-bold">Sales Opportunities</span>
            </div>
          </v-col>

          <v-divider vertical></v-divider>
          <v-col>
            <div class="d-flex align-center justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="50"
                :width="8"
                :value="averageRiskScoreAllProspects"
                color="primary"
              >
                {{ averageRiskScoreAllProspects }}
              </v-progress-circular>

              <span class="ml-4 font-weight-bold">Average Risk Score</span>
            </div>
          </v-col></v-row
        >
      </v-card-text>
    </v-card>

    <v-row>
      <v-col>
        <div>
          <h2 class="text-h6 mt-8 mb-4">{{ detailItem.title }} Advisors</h2>
        </div>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-data-table
                :headers="headers"
                :items="detailItem.advisors.items"
                :items-per-page="10"
                sort-by="aum"
                :sort-desc="true"
                outlined
                tile
                :loading="!detailItem"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.prospects.items="{ value }">
                  {{ advisorCompletedSurveysCount(value) }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                    text
                    color="primary"
                    :to="{
                      name: 'IntelDetail',
                      params: { level: nextDetail, id: item.id },
                      query: { level: nextDetail, prev: 'group' },
                    }"
                    >View Detail</v-btn
                  >
                </template>
                <template v-slot:item.aum="{ item }">
                  {{
                    currencyFormatter((item.aum / 100000).toFixed() * 100000)
                  }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col></v-row
    >
  </div>
</template>

<script>
import ActivityLineChart from "@/components/intel/ActivityLineChart";

export default {
  props: {
    detailItem: {
      type: Object,
    },
    nextDetail: {
      type: String,
    },
  },
  components: {
    ActivityLineChart,
  },
  data() {
    return {
      activityChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.4,
        elements: {
          line: {
            borderColor: "#4188EC",
            borderWidth: 4,
          },
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              // drawBorder: BORDER,
              // drawOnChartArea: CHART_AREA,
              // drawTicks: TICKS,
            },
            ticks: {
              // display: false,
              // maxRotation: 0,
              // minRotation: 0,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      },
      activityChartData: {
        labels: [
          "21-Jul",
          "22-Jul",
          "23-Jul",
          "24-Jul",
          "25-Jul",
          "26-Jul",
          "27-Jul",
          "28-Jul",
          "29-Jul",
          "30-Jul",
          "31-Jul",
          "1-Aug",
          "2-Aug",
          "3-Aug",
        ],
        datasets: [
          {
            data: [16, 25, 21, 61, 66, 89, 32, 12, 14, 44, 52, 78, 62, 25],
          },
        ],
      },

      // labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      // value: [200, 675, 410, 390, 310, 460, 250, 240],
      headers: [
        {
          text: "Last Name",
          align: "left",
          sortable: true,
          value: "lastName",
        },
        {
          text: "First Name",
          align: "left",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email",
        },
        {
          text: "Phone",
          align: "left",
          sortable: true,
          value: "phone",
        },
        {
          text: "Completed Surveys",
          align: "center",
          sortable: true,
          value: "prospects.items",
        },
        {
          text: "Sales Opportunities",
          align: "center",
          sortable: true,
          value: "recProductsCount",
        },
        {
          text: "AUM Identified",
          align: "center",
          sortable: true,
          value: "aum",
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    chartStyles() {
      return {
        height: `${150}px`,
        width: "100%",

        position: "relative",
      };
    },
    countProspects() {
      let count = 0;
      this.detailItem.advisors.items.forEach((a) => {
        count += a.prospects.items.length;
      });
      return count;
    },
    countProspectCompletedSurveys() {
      let count = 0;
      this.detailItem.advisors.items.forEach((a) => {
        a.prospects.items.forEach((p) => {
          if (p.scoreSurveyComplete) {
            count++;
          }
        });
      });
      return count;
    },
    averageRiskScoreAllProspects() {
      let sum = 0;
      let count = 0;
      this.detailItem.advisors.items.forEach((a) => {
        a.prospects.items.forEach((p) => {
          if (p.scoreSurveyResults) {
            const score = JSON.parse(p.scoreSurveyResults).final_score;

            if (score) {
              sum += score;
              count++;
            }
          }
        });
      });

      const average = Math.round(sum / count);
      return average;
    },
  },
  methods: {
    currencyFormatter(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value);
    },
    advisorCompletedSurveysCount(prospects) {
      let count = 0;
      prospects.forEach((p) => {
        if (p.scoreSurveyComplete) {
          count++;
        }
      });
      return count;
    },
  },
};
</script>

<style lang="scss" scoped></style>
